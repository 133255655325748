import React from "react";
import './Result.css'


function Result({clicks}) {

    return(
        <h2>Quantity: {clicks}</h2>
    );
}

export default Result;
